export const STORAGE_KEYS = {
    TONICAPP_SSID: 'TONICAPP_SSID',
    TONICAPP_LANG: 'TONICAPP_LANG',
    REMEMBER_LOGIN: 'TONICAPP_REMEMBER_LOGIN',
    USER_INFO_KEY: 'userInfo',
    CONTEXTUAL_DATA: 'CONTEXTUAL_DATA',
    SEARCH_FILTER: 'TONICAPP_SEARCH_FILTER',
    SEARCH_FILTER_PAGE: 'TONICAPP_SEARCH_FILTER_PAGE',
    FINISH_LOGIN: 'TONICAPP_FINISH_LOGIN',
};
