<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()"
           href="#"
           class="sidebar-toggle"
           *ngIf="showMenu">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo"
           href="#"
           (click)="navigateHome()">
            <img src="assets/images/clients-platform-logo.svg"
                 alt="Clients Platform Logo" />
            | {{ userType === type.TONICAPP ? 'Admin' : 'backoffice.userType-' + userType | translate }}
        </a>
    </div>
</div>

<div class="header-container">
    <div class="credits-container"
         *ngIf="userCredits !== null && userCreditsLimit !== null && userType !== type.TONICAPP">
        <div class="progress-bar"
             [ngStyle]="{'background': getProgressStyle()}">
            <progress [value]="userCredits"
                      min="0"
                      [max]="userCreditsLimit"
                      style="visibility:hidden;height:0;width:0;"></progress>
        </div>
        <div class="available-credits"
             *ngIf="userCredits > 0">
            {{ userCredits }}/{{ userCreditsLimit }} {{ 'header.available-credits' | translate }}
        </div>
        <div class="not-available-credits"
             *ngIf="userCredits === 0">
            <span>{{ 'header.not-available-credits' | translate }}</span>
            <span>{{ 'header.contact' | translate }} <a href="mailto:{{'header.email-contact' | translate}}">
                    <u>{{ 'header.email-contact' | translate }}</u>
                </a>
            </span>
        </div>
    </div>
    <nb-actions size="small">
        <nb-action class="user-action">
            <nb-user [nbContextMenu]="userMenu"
                     nbContextMenuTag="user-menu"
                     [onlyPicture]="userPictureOnly"
                     [name]="userName">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
