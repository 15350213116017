
export class TalentEventProperties {
    application = 'Tonicapp - Talent';
    feature_group: string;
    email?: string;
    option_select?: string;
    menu_name?: string;
    country?: string;
    region?: string;
    distance?: string;
    specialty?: string;
    sub_specialty?: string;
    n_results?: number;
    filter_type?: string;
    tonicapp_id?: number[] | string;
    chat_list_id?: string;
    message?: string;

    // Messages
    message_title?: string;
    message_body?: string;
    message_count?: number;
    credits_consumed?: number;
    tonic_user_available?: boolean;

    from_chat_list_id?: string;
    to_chat_list_id?: string;
    user_list?: string;
}

export class TalentEventUser {
    user_id?: string;
    // application
    name?: string;
    // first_name?: string;
    // last_name?: string;
    // phone_country_code
    // phone
    // country_of_practice
    email?: string;
    user_type?: string;
    user_role?: string;
    client?: string;
    // proposal_id
    // subscription_status
    // subscription_expiry_date
    subscription_credits?: number;
    subscription_credit_limit?: number;
    // registration_date
    // inactivation_date
    // update_date
}


export class TalentEventModel {
    logger_version = '6.0';
    event: string;
    timestamp: string;
    feature: string;

    properties: TalentEventProperties = new TalentEventProperties();
    user: TalentEventUser = new TalentEventUser();

    constructor() {
        this.timestamp = new Date().toISOString();
    }
}
