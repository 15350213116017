import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { STORAGE_KEYS } from '../constants/storage-keys.constant';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    public currentLang = environment.defaultLanguage;
    public defaultLang = environment.defaultLanguage;
    public supportedLangs = environment.supportedLangs;

    constructor(
        public translate: TranslateService,
    ) {
        this.currentLang = this.initLangFromStorage();
    }

    public initLangFromStorage() {
        let currentLang = environment.defaultLanguage;
        const storageLang: any = localStorage.getItem(STORAGE_KEYS.TONICAPP_LANG);
        if (storageLang) {
            currentLang = storageLang;
        }
        this.setLang(currentLang);
        return currentLang;
    }

    setLang(lang: string) {
        this.currentLang = lang ? lang : this.currentLang;
        localStorage.setItem(STORAGE_KEYS.TONICAPP_LANG, this.currentLang);
        this.translate.use(this.currentLang);
    }

    getLang(): string {
        return this.currentLang;
    }

    async getTranslationAsync(key: string, replacements?: any) {
        return await firstValueFrom(this.translate.get(key, replacements));
    }

    getReplacements(key: string, replaceKeys: string[], flavour: string) {
        const replacements: any = {};
        flavour = flavour?.replace('-stg', '');
        replaceKeys.forEach((keyName: string) => {
            const fileKey = `replacements.${keyName}.${flavour?.replace('-stg', '')}`;
            replacements[keyName] = this.translate.instant(fileKey);
        });
        return this.translate.instant(key, replacements);
    }

    getDynamicReplacements(key: string, flavour: string) {
        flavour = flavour?.replace('-stg', '');
        let instant = this.translate.instant(key);
        const replacements: any = {};

        try {
            const replaceKeys = instant?.match(/\{\{\w+\}\}/g)?.map((a: string) => a.replace(/(\{|\})/g, ''));

            replaceKeys?.forEach((keyName: string) => {
                const fileKey = `replacements.${keyName}.${flavour?.replace('-stg', '')}`;
                replacements[keyName] = this.translate.instant(fileKey);
            });

            instant = this.translate.instant(key, replacements);

        } catch (error) {
            console.error(error);
        }
        return instant;
    }

    getDynamicReplacements2(key: string, flavour: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            flavour = flavour?.replace('-stg', '');
            let instant = await this.getTranslationAsync(key);
            const replacements: any = {};

            try {
                const replaceKeys = instant?.match(/\{\{\w+\}\}/g)?.map((a: string) => a.replace(/(\{|\})/g, ''));

                replaceKeys?.forEach((keyName: string) => {
                    const fileKey = `replacements.${keyName}.${flavour?.replace('-stg', '')}`;
                    replacements[keyName] = this.translate.instant(fileKey);
                });

                instant = this.translate.instant(key, replacements);

            } catch (error) {
                console.error(error);
                reject('');
            }
            resolve(instant);
        });
    }

    getTimezoneByLocale(locale?: string) {
        if (!locale) {
            locale = this.currentLang;
        }
        let timezone = "Europe/Lisbon";
        try {
            const timezones: any = {
                'pt-PT': "Europe/Lisbon",
                'es-ES': "Europe/Madrid",
                'fr-FR': "Europe/Paris",
                'it-IT': "Europe/Rome",
                'en-GB': "Europe/London",
                'de-DE': "Europe/Berlin"
            };
            if (this.supportedLangs.includes(locale)) {
                timezone = timezones[locale];
            }
        } catch (error) {
            console.error(error);
        }
        return timezone;
    }

    public getLangFromStorage() {
        let currentLang = environment.defaultLanguage;
        const storageLang: any = localStorage.getItem(STORAGE_KEYS.TONICAPP_LANG);
        if (storageLang) {
            currentLang = storageLang;
        }
        if (!currentLang) {
            currentLang = environment.defaultLanguage;
        }
        return currentLang;
    }

    public getCountryLabel(country: string) {
        switch (country) {
            case 'pt-PT':
                return 'Portugal';
            case 'es-ES':
                return 'Spain';
            case 'fr-FR':
                return 'France';
            case 'it-IT':
                return 'Italy';
            case 'en-GB':
                return 'United Kingdom';
            default:
                return country;
        }
    }
}
