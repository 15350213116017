export const environment = {
    production: false,
    baseURL: 'https://clients.beta.tonicapp.io',
    firebase: {
        apiKey: "AIzaSyAgKybAm69Fk7MDix4zVUrBUT_2IHggCE8",
        authDomain: "tonic-clients-platform-beta.firebaseapp.com",
        projectId: "tonic-clients-platform-beta",
        storageBucket: "tonic-clients-platform-beta.appspot.com",
        messagingSenderId: "924245431180",
        appId: "1:924245431180:web:961343f963e796705bbca9",
        measurementId: "G-1YM8G056V5",
        databaseURL: "https://tonic-clients-platform-beta-default-rtdb.europe-west1.firebasedatabase.app",
    },
    mapKey: "pk.eyJ1IjoidG9uaWNhcHAiLCJhIjoiY2xtOWE3cjV5MGhoMzNjcXFucnQ0aGxubSJ9.2LYXnYwV7YBuOw3TLUwCBg",
    baseMapURL: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
    clientsApiURL: "https://clients.api.beta.tonicapp.io",
    tonicappApiURL: "https://api.beta.tonicapp.com",
    CWTSearch: '5kxEmwpyi3p8GYPKaXJFrWXyZDKzXkNQ',
    CWTMessage: 'daADsdLlksakjfKJSs34aSDda1231dsadsfSxz',
    sendbirdKey: "1EFC2E1C-4BF5-4174-8DB7-CFB99F118478",
    defaultLanguage: 'pt-PT',
    supportedLangs: ['pt-PT', 'es-ES', 'it-IT', 'fr-FR', 'en-GB'],
    hitsPerPage: 20,
    MESSAGE_CWT: `daADsdLlksakjfKJSs34aSDda1231dsadsfSxz`,
    MAIL_RECEIVERS: [
        "engineering@tonicapp.com"
    ],
    PROFILE_SERVICE_CWT: 'NkW7w7Ft2Pwtbq',
    cluadiaSendbirdId: 13859
};
