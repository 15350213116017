import { Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from '../services/translator.service';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'messageDate'
})
export class MessageDatePipe implements PipeTransform {

    constructor(
        private translateService: TranslatorService,
        private datePipe: DatePipe
    ) { }

    transform(value: Date | string | number): unknown {
        switch (this.translateService.getLang()) {
            case 'pt-PT':
                return this.datePipe.transform(value, 'dd \'de\' MMMM', 'UTC', 'pt-PT');
            case 'es-ES':
                return this.datePipe.transform(value, 'dd \'de\' MMMM', 'UTC', 'es');
            case 'fr-FR':
                return this.datePipe.transform(value, 'dd MMMM', 'UTC', 'fr');
            case 'it-IT':
                return this.datePipe.transform(value, 'dd MMMM', 'UTC', 'it');
            default:
                return this.datePipe.transform(value, 'MMMM dd', 'UTC', 'en');
        }
    }

}
