import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthService } from './auth.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    constructor(
        private fireAuth: AngularFireAuth,
        private fireRealtime: AngularFireDatabase,
        private translateService: TranslatorService,
        private authService: AuthService
    ) { }

    saveUserOnDB(uid: string, email: string, userType: string, name: string, userCreditLimit: string, userCredits: null | number) {
        this.fireRealtime.database.ref(`users/${uid}`).set({
            name,
            email,
            uid,
            userType: parseInt(userType),
            userCreditLimit: parseInt(userCreditLimit),
            userCredits: userCredits === null ? parseInt(userCreditLimit) : userCredits,
        });
    }

    getUsers() {
        return this.fireRealtime.list('users').valueChanges();
    }

    getUserInfo() {
        return this.fireRealtime.object(`users/${this.authService.getUid()}`).valueChanges();
    }

    getUserById(uid: string) {
        return this.fireRealtime.object(`users/${uid}`).valueChanges();
    }

    createUser(email: string) {
        return this.fireAuth.createUserWithEmailAndPassword(email, Math.random().toString(36).slice(-8));
    }

    setUserLang(uid: string) {
        this.getUserById(uid).subscribe((user: any) => {
            this.translateService.setLang(user.lang);
        });
    }

    decreaseUserCredits(userData: any, credits: number) {
        this.fireRealtime.database.ref(`users/${userData.uid}`).update({
            userCredits: userData.userCredits - credits
        });
    }
}
