import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { STORAGE_KEYS } from '../constants/storage-keys.constant';
import { TalentEventModel } from '../models/instrumentation.model';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class InstrumentationService {

    constructor(
        private http: HttpClient,
        private translateService: TranslatorService
    ) { }

    public prepareInstrumentation() {
        const obj = new TalentEventModel();
        if (localStorage.getItem(STORAGE_KEYS.USER_INFO_KEY)) {
            const parsedUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_INFO_KEY));
            const userRole = this.translateService.translate.instant('backoffice.userType-' + parsedUser.userType);
            obj.user = {
                user_id: parsedUser.uid,
                name: parsedUser.name,
                email: parsedUser.email,
                user_type: 'Regular',
                user_role: userRole,
                client: parsedUser.name,
                subscription_credits: parsedUser.userCredits,
                subscription_credit_limit: parsedUser.userCreditLimit
            };
        }

        return obj;
    }

    public async sendLog(data: any) {
        return await firstValueFrom(this.http.post(`${environment.tonicappApiURL}/v1/instrumentation/external`, data));
    }
}
