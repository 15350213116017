import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { STORAGE_KEYS } from '../../../core/constants/storage-keys.constant';
import { UserType } from '../../../core/constants/userType.enum';
import { AuthService } from '../../../core/services/auth.service';
import { InstrumentationService } from '../../../core/services/instrumentation.service';
import { TranslatorService } from '../../../core/services/translator.service';
import { UserManagementService } from '../../../core/services/user-managment.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() showMenu = true;

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly = false;
    user: any;
    userName!: string;
    userType!: number;
    type = UserType;

    userMenu = [
        { title: '', key: '' }
    ];

    userCredits: null | number = null;
    userCreditsLimit: null | number = null;

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private authService: AuthService,
        private translateService: TranslatorService,
        private userManagementService: UserManagementService,
        private instrumentationService: InstrumentationService
    ) { }

    ngOnInit() {
        this.getMenuLabels();
        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.menuService.onItemClick()
            .pipe(
                filter(({ tag }) => tag === 'user-menu'),
            )
            .subscribe((item: any) => {
                if (item.item.key === 'logout') {
                    this.authService.logout();
                }

                switch (item.item.key) {
                    case 'logout':
                        this.logMenuClick('Logout');
                        break;
                    case 'privacy':
                        this.logMenuClick('Privacy Policy');
                        break;
                    case 'terms':
                        this.logMenuClick('Terms of Service');
                        break;
                    default:
                        break;
                }
            });
        if (this.authService.getUid()) {
            this.userManagementService.getUserInfo().subscribe((user: any) => {
                this.userName = user.name;
                this.userType = user.userType;
                this.userCredits = user.userCredits;
                this.userCreditsLimit = user.userCreditLimit;

                localStorage.setItem(STORAGE_KEYS.USER_INFO_KEY, JSON.stringify(user));

                if (localStorage.getItem(STORAGE_KEYS.FINISH_LOGIN) === 'true') {
                    this.logLogin(user);
                    localStorage.removeItem(STORAGE_KEYS.FINISH_LOGIN);
                }
            });

        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    async getMenuLabels() {

        const menuLabels = await this.translateService.getTranslationAsync('menu');
        const newMenuLabels = [];
        Object.entries(menuLabels).forEach(([key, value]) => {
            newMenuLabels.push({
                title: value,
                key,
            });
        });
        this.userMenu = newMenuLabels;
    }

    getProgressStyle() {
        if (this.userCredits > 0) {
            const percentage = (this.userCredits / this.userCreditsLimit) * 100;
            return `radial-gradient(closest-side, white 70%, transparent 70% 100%), conic-gradient(#00D68F ${percentage}%, #EDF1F7 0)`;
        } else {
            return `radial-gradient(closest-side, white 70%, transparent 70% 100%), conic-gradient(#00D68F 0%, #ffd6d8 0)`;
        }
    }

    logMenuClick(option: string) {
        const obj = this.instrumentationService.prepareInstrumentation();
        obj.event = 'Settings Click';
        obj.feature = 'Settings';
        obj.properties.feature_group = 'Settings';
        obj.properties.option_select = option;

        this.instrumentationService.sendLog(obj);
    }

    async logLogin(user: any) {
        try {
            const obj = this.instrumentationService.prepareInstrumentation();
            obj.event = 'User Login Finish';
            obj.feature = 'User Login';
            obj.properties.feature_group = 'Account';
            obj.properties.email = user.email;

            await this.instrumentationService.sendLog(obj);
        } catch (error) {
            console.log(error);
        }
    }
}
