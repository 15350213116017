import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslatorService } from '../services/translator.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor(
        private langService: TranslatorService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const lang = this.langService.currentLang;
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': lang,
            'Software-Type': 'Application',
        } as any;

        if (request?.url?.includes('/v1/message/mail')) {
            headers['Authorization'] = `CWT ${environment.MESSAGE_CWT}`;
        }

        request = request.clone({
            setHeaders: headers,
        });
        return next.handle(request).pipe(take(2));
    }
}
