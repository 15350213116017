import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private fireAuth: AngularFireAuth,
        private router: Router,
        private fireRealtime: AngularFireDatabase
    ) { }

    public loginWithLink(email: string) {
        return this.fireAuth.sendSignInLinkToEmail(email, {
            url: `${environment.baseURL}/auth/finishLogin?email=${email}`,
            handleCodeInApp: true,
        });
    }

    public login(email: string, password: string) {
        return this.fireAuth.signInWithEmailAndPassword(email, password);
    }

    public logout() {
        this.fireAuth.signOut()
            .then(() => {
                // Sign-out successful.
                this.fireRealtime.database.goOffline();
                localStorage.clear();
                this.router.navigate(['/auth/logout']);
            })
            .catch((error) => {
                // An error happened.
                console.error('An error happened.', error);
            });
    }

    getUid() {
        try {
            const user = localStorage.getItem('user');
            if (user) {
                const parsedUser = JSON.parse(user);
                return parsedUser.user.uid;
            }
        } catch (error) {
            console.error('Error getting user from localStorage', error);
        }
    }

    getEmail() {
        try {
            const user = localStorage.getItem('user');
            if (user) {
                const parsedUser = JSON.parse(user);
                return parsedUser.user.email;
            }
        } catch (error) {
            console.error('Error getting user from localStorage', error);
        }
    }
}
