import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { TranslatorService } from './core/services/translator.service';
import { UserManagementService } from './core/services/user-managment.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private userManagementService: UserManagementService,
        private authService: AuthService,
        private translateService: TranslatorService
    ) {
        if (this.authService.getUid()) {
            const uid = this.authService.getUid();
            this.userManagementService.setUserLang(uid);
        } else {
            this.translateService.initLangFromStorage();
        }
    }
}
