
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
} from '@nebular/theme';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from } from 'rxjs';
import { DatabaseModule } from '@angular/fire/database';
import { MessageDatePipe } from './core/pipes/message-date.pipe';
import { HeadersInterceptor } from './core/interceptors/headers.interceptor';

export class CustomTranslateLoader implements TranslateLoader {
    getTranslation(lang: string) {
        return from(import(`../assets/i18n/${lang}.json`));
    }
}

export function CustomLoaderFactory() {
    return new CustomTranslateLoader();
}

@NgModule({
    declarations: [AppComponent, MessageDatePipe],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        ThemeModule.forRoot(),
        CoreModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: CustomLoaderFactory,
                deps: [HttpClient],
            },
        }),
        DatabaseModule,
    ],
    exports: [
        TranslateModule,
        MessageDatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true }
    ]
})
export class AppModule {
}

